import { NAME as QuantityCounterName } from '@spa-ec/components/QuantityCounter/QuantityCounter.theme';
import { NAME as ColumnCardName } from '@spa-ec/components/Card/ColumnCard.theme';
import { NAME as HeaderName } from '@spa-ec/components/Header/Header.theme';
import { NAME as ContentPageName } from '@spa-ec/routes/ContentPage/ContentPage.theme';
import { NAME as CategoryListingName } from '@spa-ec/components/CategoryListing/CategoryListing.theme';
import { NAME as PageTitleName } from '@spa-ec/components/StaticPageComponents/PageTitle.theme';
import { NAME as CartTableName } from '@spa-ec/displayComponents/CartTable/CartTable.theme';
import { NAME as PrisjaktCheapestName } from '@spa-ec/components/PrisjaktCheapest/PrisjaktCheapest.theme';
import { NAME as SiteLogoName } from '@spa-ec/components/Header/HeaderSiteLogo/SiteLogo.theme';
import { CategoryListingType } from '@spa-ec/components/CategoryListing/constants';
import { NAME as ShipmentAddressFormName, } from '@spa-ec/components/Checkout/AddressSection/ShipmentAddressForm.theme';
import theme from './theme.conf';
import { NAME as CardDescriptionName } from '@spa-ec/components/Card/CardDescription/CardDescription.theme';
const contentPageTheme = {
    showSideNavigationOnStaticPages: true,
    showTitleOnContentPageDesktop: true,
    showTitleOnContentPageMobile: true,
    showHomepageCategorySection: true,
    linkColor: theme.col.primary.fg,
    linkColorHover: theme.col.secondary.fg,
    sideContentPadding: 'px-4',
};
const headerTheme = {
    showCountrySelector: false,
};
const quantityCounterTheme = {
    maxQuantity: 999,
};
const columnCardTheme = {
    alignHeaderPreambleLeft: true,
    descriptionTextSize: 'text-sm',
};
const categoryListingTheme = {
    listingType: CategoryListingType.SUBCATEGORIES,
    forceShowPagination: true,
};
const pageTitleTheme = {
    noBorder: true,
    showLogoutIcon: false,
    combineSections: true,
};
const cartTableTheme = {
    showProductDetails: true,
    headerColor: 'col-primary',
    showPerKronaInCartTable: false,
};
const shipmentAddressFormTheme = {
    showShippingAddressCellphone: true,
};
const prisjaktCheapestTheme = {
    storeIds: { 'lamportillallt-se': 16460 },
};
const siteLogoTheme = {
    siteLogo: {
        imagesRootPath: '/images/sitelogo',
        paths: {
            b2c: {
                default: '/lamportillallt_logo.svg',
            },
            b2b: {
                default: '/lamportillallt_logo.svg',
            },
        },
    },
};
const cardDescriptionTheme = {
    hideShortCopyText: true,
};
export default {
    [ContentPageName]: contentPageTheme,
    [QuantityCounterName]: quantityCounterTheme,
    [ColumnCardName]: columnCardTheme,
    [HeaderName]: headerTheme,
    [CategoryListingName]: categoryListingTheme,
    [PageTitleName]: pageTitleTheme,
    [CartTableName]: cartTableTheme,
    [PrisjaktCheapestName]: prisjaktCheapestTheme,
    [SiteLogoName]: siteLogoTheme,
    [ShipmentAddressFormName]: shipmentAddressFormTheme,
    [CardDescriptionName]: cardDescriptionTheme,
};
